/**
 * Swiper Helpers
 * */

export const updateFeatureSellerSlide = (swiperSeller: any) => {
  // Set slidesPerView if slideLength < 5
  const width = document.body.clientWidth;
  const slideLength = swiperSeller.slides.length;
  if (slideLength > 3 && width >= 768) {
    document.getElementById('featured-sellers-name-1')?.classList.remove('hidden');
  }

  //swiperSeller.params.breakpoints
  swiperSeller.update();
  // On slide change
  swiperSeller?.on('slideChangeTransitionEnd', function (swiper: any) {
    let {realIndex: firstIndexActive, slides: {length: lengthOfSlide}} = swiper;
    // Hidden all labels after slide change
    hiddenAllOfSellersSwiperLabels(lengthOfSlide);
    if (width >= 768) {
      let next = 1;
      let overflowIndex = Math.abs((lengthOfSlide - next) - firstIndexActive);
      let currentActive = (firstIndexActive + next) > (lengthOfSlide - 1)
        ? overflowIndex
        : firstIndexActive + next;
      document.getElementById(`featured-sellers-name-${currentActive}`)
        ?.classList.remove('hidden');
    }
  });
}

export const hiddenAllOfSellersSwiperLabels = (lengthOfSlide: number) => {
  for (let i = 0; i < lengthOfSlide; i++) {
    document.getElementById(`featured-sellers-name-${i}`)?.classList.add('hidden');
  }
}
